import { useState } from "react";
import emailjs from "emailjs-com";

export default function Contact() {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [message, setMessage] = useState("");
  const [antiSpam, setAntiSpam] = useState("");
  const [success, setSuccess] = useState(false);
  const [error, setError] = useState(false);

  const SERVICE_ID = "service_2kjljdn";
  const TEMPLATE_ID = "template_j8jf2ps";
  const USER_ID = "user_6Wxmm1ec6zlSCgYdGjJPL";

  const onSubmit = async (event) => {
    event.preventDefault();
    setSuccess(false);
    setError(false);

    if (antiSpam !== "") {
      setAntiSpam("");
      resetForm();
      return;
    }

    emailjs.sendForm(SERVICE_ID, TEMPLATE_ID, event.target, USER_ID).then(
      (result) => {
        resetForm();
      },
      (error) => {
        setError(true);
      }
    );
  };

  const resetForm = () => {
    setName("");
    setEmail("");
    setMessage("");
    setSuccess(true);
  };

  return (
    <section id="contact" className="contact container container--px">
      <h1 className="section-title">Contact Me</h1>
      <div className="contact__container">
        <p className="contact__text">
          Have any questions about me, my projects, or just want to say hi?
          Please feel free to get in touch, and I’ll gladly start a dialogue.
        </p>

        <form onSubmit={onSubmit} className="contact__form">
          {success && (
            <div className="contact__message contact__message--success">
              Message successfully sent. I will get back to you as soon as
              possible.
            </div>
          )}
          {error && (
            <div className="contact__message contact__message--error">
              Looks like something went wrong. Please try again.
            </div>
          )}
          <label>
            <p>Name</p>
            <input
              type="text"
              name="from_name"
              value={name}
              onChange={(e) => setName(e.target.value)}
            />
          </label>
          <label>
            <p>Email</p>
            <input
              type="email"
              name="reply_to"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />
          </label>
          <label className="contact__antispam" htmlFor="">
            <p>URL</p>
            <input
              type="text"
              name="url"
              value={antiSpam}
              placeholder="Leave this field empty"
              onChange={(e) => setAntiSpam(e.target.value)}
            />
          </label>
          <label>
            <p>Message</p>
            <textarea
              type="text"
              name="message"
              value={message}
              onChange={(e) => setMessage(e.target.value)}
            />
          </label>
          <input type="submit" id="send" value="Send" />
        </form>
      </div>
    </section>
  );
}
