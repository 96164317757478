import projects from "../data/projects";

export default function Portfolio() {
  return (
    <section id="projects" className="container container--px">
      <h1 className="section-title">My Projects</h1>
      <div className="portfolio__item-container flex">
        {projects.map((project, index) => {
          return (
            <article key={index} className="portfolio__item">
              <img
                className="portfolio__image"
                src={project.image}
                alt={project.name}
              />
              <div className="portfolio__content flex flex-jc-sb">
                <div>
                  <div className="portfolio__technology-container flex">
                    {project.technologies.map((technology, index) => (
                      <div key={index} className="technology">
                        {technology}
                      </div>
                    ))}
                  </div>
                  <h2 className="portfolio__name">{project.name}</h2>

                  <p className="portfolio__text">{project.text}</p>
                </div>
                <div className="portfolio__links flex">
                  {project.mainLink && (
                    <a
                      href={project.mainLink.link}
                      className="portfolio__link portfolio__link-main"
                    >
                      {project.mainLink.description}
                    </a>
                  )}
                  {project.secondaryLink && (
                    <a
                      href={project.secondaryLink.link}
                      className="portfolio__link portfolio__link-second"
                    >
                      {project.secondaryLink.icon}
                    </a>
                  )}
                </div>
              </div>
            </article>
          );
        })}
      </div>
    </section>
  );
}
