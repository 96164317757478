export default function Hero() {
  return (
    <section id="Hero" className="container container--px">
      <div className="hero__container flex">
        <div className="hero__text">
          <h1>David Ameny</h1>
          <h2>Web Developer</h2>
          <p>
            I’m a software developer that builds stylish, user-friendly web
            applications.
          </p>
          <a className="hero__cta" href="#projects">
            My Work
          </a>
        </div>
      </div>
    </section>
  );
}
