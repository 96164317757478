import project1Img from "../images/projects/TinDog.jpg";
import project2Img from "../images/projects/Simon.png";
import project3Img from "../images/projects/Flappy Bird.png";

const projects = [
  {
    id: 1,
    image: project1Img,
    name: "TinDogs",
    text: "A landing page concept for a fictional service I’m sure man’s best friend would love. The page is fully responsive, and utilizes Bootstrap for responsive elements such as the navigation menu and testimonial carousel.",
    technologies: ["HTML", "Bootstrap"],
    mainLink: {
      link: "https://dameny.github.io/TinDog",
      description: "Live Site",
    },
    secondaryLink: {
      link: "https://github.com/dameny/TinDog",
      icon: <i className="devicon-github-original"></i>,
    },
  },
  {
    id: 2,
    image: project2Img,
    name: "Simon",
    text: "The classic game everyone knows in web browser form. How good is your memory?",
    technologies: ["JQuery", "HTML", "CSS"],
    mainLink: {
      link: "https://dameny.github.io/memory-challenge",
      description: "Live Site",
    },
    secondaryLink: {
      link: "https://github.com/dameny/memory-challenge",
      icon: <i className="devicon-github-original"></i>,
    },
  },
  {
    id: 3,
    image: project3Img,
    name: "Flappy Bird",
    text: "A re-creation of the mobile game took over people’s phones and caused frustration on a global scale. This version was built using LibGDX framework and Android.",
    technologies: ["Java", "Android", "LibGDX"],
    mainLink: {
      link: "https://github.com/dameny/Flappy_Bird_Clone",
      description: "Source Code",
    },
  },
];

export default projects;
