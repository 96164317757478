import links from "../data/links";

export default function Footer() {
  const year = new Date().getFullYear();

  return (
    <section className="footer container container--px">
      <div className="footer__container flex">
        <div className="footer__social">
          <a href={links.github} className="footer__social-icon">
            <i className="devicon-github-original"></i>
          </a>
          <a href={links.linkedIn} className="footer__social-icon">
            <i className="devicon-linkedin-plain"></i>
          </a>
          <a href="#contact" className="footer__social-icon">
            <i className="far fa-envelope"></i>
          </a>
        </div>
        <div className="footer__text">
          <div>Designed & Developed by David Ameny</div>
          <div className="footer__credit">
            Hero Image by{" "}
            <a href="https://unsplash.com/@markusspiske?utm_source=unsplash&utm_medium=referral&utm_content=creditCopyText">
              Markus Spiske
            </a>{" "}
            on Unsplash
          </div>
          <div className="footer__copyright"> © {year} David Ameny</div>
        </div>
      </div>
    </section>
  );
}
