import { useState, useEffect } from "react";
import classNames from "classnames";

export default function Header() {
  const [menuOpen, setMenuOpen] = useState(false);

  const menuToggleClass = classNames({
    header__links: true,
    flex: true,
    show: menuOpen,
  });

  const buttonClass = classNames({
    header__toggle: true,
    "not-visible-desktop": true,
    open: menuOpen,
  });

  const toggleMenu = () => {
    setMenuOpen(!menuOpen);
  };

  const closeMenu = () => {
    setMenuOpen(false);
  };

  const lockBodyScroll = (toggle) => {
    const body = document.querySelector("body");
    if (toggle) {
      body.classList.add("no-scroll");
    } else {
      body.classList.remove("no-scroll");
    }
  };

  useEffect(() => {
    if ("onorientationchange" in window) {
      window.addEventListener("orientationchange", closeMenu);

      return () => {
        window.removeEventListener("orientationchange", closeMenu);
      };
    }
  }, []);

  useEffect(() => {
    lockBodyScroll(menuOpen);
  }, [menuOpen]);

  return (
    <header className="header container container--px">
      <nav className="flex flex-jc-sb flex-ai-c">
        <div className="header__logo">
          <i className="fas fa-code" />
          <span>David</span>
        </div>
        {menuOpen && <div className="overlay" onClick={closeMenu} />}
        <button
          id="hamburger-toggle"
          className={buttonClass}
          onClick={toggleMenu}
          aria-controls="navbar-menu"
          aria-label="Menu toggle"
          aria-expanded={menuOpen}
        >
          <span></span>
          <span></span>
          <span></span>
        </button>

        <ul
          id="navbar-menu"
          className={menuToggleClass}
          aria-labelledby="hamburger-toggle"
        >
          <li className="header__link-item">
            <a className="header__link" href="#about" onClick={closeMenu}>
              About
            </a>
          </li>
          <li className="header__link-item">
            <a className="header__link" href="#projects" onClick={closeMenu}>
              My Projects
            </a>
          </li>
          <li className="header__link-item">
            <a className="header__link" href="#contact" onClick={closeMenu}>
              Contact
            </a>
          </li>
        </ul>
      </nav>
    </header>
  );
}
