import profileImg from "../images/About Pic.jpg";
import skills from "../data/skills";

export default function About() {
  return (
    <section id="about" className="about container container--px">
      <h1 className="section-title">About</h1>
      <div className="about__container flex">
        <img src={profileImg} alt="David Ameny" className="about__img" />
        <div className="about__content">
          <div className="about__text">
            <h2>
              I’m a software developer with a passion for building stylish,
              responsive, and user-friendly web applications
            </h2>
            <p>
              I’ve always enjoyed building new things, and software unlocks the
              limits of what is possible. This love of building led me to a
              career in engineering. I started my career developing software for
              automating the testing process of electronic products.
            </p>
            <p>
              Web technologies are now crucial for any business whether it's
              internal applications a business relies on, a customer facing web
              site, or even just simply building a web presence. Because of
              this, my work started to encompass more and more web development.
              I decided I love this work, and this is what I want to focus on.
              From picking the right tech stack, to making that that pixel
              perfect layout there is lot of opportunity for creativity and
              plenty of chances to build something cool.
            </p>
          </div>
          <h3>Here are some of the technologies I have been using recently</h3>
          <div className="about__skills flex">
            {skills.map((skill) => {
              return (
                <div className="about__skill-card" key={skill.id}>
                  {skill.icon}
                  {skill.name}
                </div>
              );
            })}
          </div>
        </div>
      </div>
    </section>
  );
}
