const skills = [
  {
    id: 1,
    icon: <i className="devicon-react-original"></i>,
    name: "React",
  },
  {
    id: 2,
    icon: <i className="devicon-nodejs-plain"></i>,
    name: "Node.js",
  },
  {
    id: 3,
    icon: <i className="devicon-javascript-plain"></i>,
    name: "JavaScript",
  },
  {
    id: 4,
    icon: <i className="devicon-python-plain"></i>,
    name: "Python",
  },
  {
    id: 5,
    icon: <i className="devicon-typescript-plain"></i>,
    name: "TypeScript",
  },
  {
    id: 6,
    icon: <i className="devicon-html5-plain"></i>,
    name: "HTML",
  },
  {
    id: 7,
    icon: <i className="devicon-css3-plain"></i>,
    name: "CSS",
  },
  {
    id: 8,
    icon: <i className="devicon-sass-original"></i>,
    name: "Sass",
  },
];

export default skills;
